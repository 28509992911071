module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Roboto","file":"https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"},{"name":"Roboto Condensed","file":"https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"579f958cb2c69a1ce9ad5531ea2ed745"},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en","plugins":[null]}],"fields":[{"name":"caseName","store":true,"attributes":{"boost":100}},{"name":"caseNameData","store":true},{"name":"prettyUrl","store":true},{"name":"filingDate","store":true},{"name":"topic","store":true},{"name":"topicData","store":true},{"name":"courtType","store":true},{"name":"courtTypeData","store":true},{"name":"relevantLaw","store":true},{"name":"relevantLawData","store":true},{"name":"currentStatus","store":true},{"name":"relevantLocation","store":true},{"name":"contentfulType","store":true},{"name":"updatedAt","store":true},{"name":"overview","store":true},{"name":"overviewDetails","store":true},{"name":"title","store":true},{"name":"topic","store":true},{"name":"topicData","store":true},{"name":"resourceType","store":true},{"name":"resourceTypeData","store":true},{"name":"publication","store":true},{"name":"publicationDate","store":true}],"resolvers":{"ContentfulCase":{},"ContentfulResource":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-GXEHW9W3SX"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
